
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import Bus from "@/common/ts/Bus";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "invite-friends-modal",
  components: {},
  props:{
    is_preview: String
  },
  data() {
    return {
      answerCardList: [],
    };
  },
  methods: {
    initData(row, is_submit) {
      this.getAnswerCard(row, is_submit);
    },
    handleLocation(e) {
      const index = parseInt(e.currentTarget.dataset.index);
      Bus.$emit("handleLocation", index);
      (this.$refs.btnClose as any).click();
    },
    handleConfirm() {
      let hasUnAnswer = false;
      this.answerCardList.forEach((row) => {
        const unAnswer = (row as any).answer_list.filter(function (item) {
          return item.is_answer != "1";
        });
        if (unAnswer.length > 0) {
          hasUnAnswer = true;
        }
      });

      let message = hasUnAnswer
        ? "尚有题目未解答，请确定是否交卷？"
        : "请确定是否交卷？";

      Toast.confirm(message, () => {
        Bus.$emit("handleSubmit");
      });
    },
    getAnswerCard(row, is_submit) {
      ApiService.post("Mock/AnswerCard", row as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.answerCardList = data.data;
            if (is_submit) {
              this.handleConfirm();
            }
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
  },
});
